import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { ActionBar, Feedback, IconButton, Layout, PageHeading } from 'shared/components';
import confirm from 'shared/utils/confirm';
import DataTable from 'shared/containers/DataTable';
import userRoles from 'shared/constants/userRoles';
import { types } from 'shared/constants/contract';
import { TaxCardTypeOverride } from 'shared/TaxCard/containers/TaxCardTypeOverride';

export const PageActionItems = ({ contractDataId, contractType, activeTaxCard }) => {
    const dispatch = useDispatch();
    const isEditMode = useSelector(select.taxCards.isEditMode);
    const isFetching = useSelector((state) => state.loading.effects.taxCards.fetchVeroTaxCard);
    const userRole = useSelector(select.userMetadata.getUserRole);
    const isVeroApiEnabled = useSelector(select.taxCards.isVeroApiEnabled);
    const pendingTaxCards = useSelector(select.taxCards.getPendingTaxCards);
    const payrollIdWithOldTaxCard = useSelector(select.taxCards.getPayrollIdsWithOldTaxCard);
    const payrollRoute = resolvePayrollRoute(userRole);

    if (isEditMode) {
        return null;
    }

    return (
        <div>
            <Layout.Container gutterSize="medium">
                <Layout.Item small="1/2">
                    <PageHeading> {_trans('tax_card.title')} </PageHeading>
                </Layout.Item>
                <Layout.Item align="right" modifierClass="u-margin-bottom-small" small="1/2">
                    <IconButton
                        mdIcon="add"
                        onClick={() => {
                            if (pendingTaxCards.length > 0) {
                                confirm(_trans('tax_card.details.has_pending_tax_card_info'), {
                                    cancelLabel: _trans('button.cancel'),
                                    proceedLabel: _trans('button.yes'),
                                }).then(() => {
                                    dispatch.taxCards.setIsEditMode(true);
                                    dispatch.taxCards.setSelectedTaxCardId(null);
                                    dispatch.taxCards.setIsOverrideVeroFetch(null);
                                }, () => {});
                            } else {
                                dispatch.taxCards.setIsEditMode(true);
                                dispatch.taxCards.setSelectedTaxCardId(null);
                                dispatch.taxCards.setIsOverrideVeroFetch(null);
                            }
                        }}
                    >
                        {_trans('tax_card.button.new')}
                    </IconButton>
                </Layout.Item>
            </Layout.Container>
            {isVeroApiEnabled && (
                <Fragment>
                    <div className="u-margin-bottom-small">{_trans('Tarkista voimassa oleva verokortti seuraavalle palkkapäivälle.', {}, 'tax_card')}</div>
                    <ActionBar alignItems="right">
                        <IconButton
                            mdIcon="refresh"
                            primary
                            onClick={() => {
                                if (activeTaxCard?.isOverrideVeroFetch) {
                                    confirm((
                                        <div>
                                            <h2>{_trans('Haluatko automaattisen verokorttihaun käyttöön?', {}, 'tax_card')}</h2>
                                            <span>
                                                {_trans('Tulonsaaajalla on käsinsyötetty verokortti, josta muutosverokorttien automaattinen haku on estetty. Valitsemalla kyllä otetaan automaattinen verokortinhaku käyttöön.', {}, 'tax_card')}
                                            </span>
                                        </div>
                                    ),
                                    {
                                        cancelLabel: _trans('button.cancel'),
                                        proceedLabel: _trans('Kyllä', {}, 'common'),
                                    }).then(() => dispatch.taxCards.fetchVeroTaxCard({ contractDataId, clearFetchOverRide: true }));
                                } else {
                                    dispatch.taxCards.fetchVeroTaxCard({ contractDataId, clearFetchOverRide: false });
                                }
                            }}
                            inProgress={isFetching}
                            disabled={! isVeroApiEnabled}
                        >
                            {_trans('tax_card.button.fetch')}
                        </IconButton>
                        {contractType === types.ASSIGNMENT_CONTRACTS_COMPENSATION_EARNER && (
                            <TaxCardTypeOverride jobContractData={contractDataId} />
                        )}
                    </ActionBar>
                    <Feedback type="info" modifierClass="u-margin-vertical">
                        {_trans('Verokortit haetaan automaattisesti ennen palkkapäivää, jonka jälkeen uudet verokortit näkyvät palvelussa.', {}, 'tax_card')}
                    </Feedback>
                    <OpenPayrollsWithOldTaxCard
                        payrolls={payrollIdWithOldTaxCard}
                        payrollRoute={payrollRoute}
                    />
                </Fragment>
            )}
        </div>
    );
};

PageActionItems.propTypes = {
    contractDataId: PropTypes.number.isRequired,
    contractType: PropTypes.number.isRequired,
    activeTaxCard: PropTypes.object,
};

PageActionItems.defaultProps = {
    activeTaxCard: {}
};

const OpenPayrollsWithOldTaxCard = ({ payrolls, payrollRoute }) => {
    if (payrolls.length === 0) {
        return null;
    }
    return (
        <Layout.Item small="1/1">
            <Feedback type="warning" modifierClass="u-margin-bottom-small">
                {_trans('Seuraavien jo hyväksyttyjen jaksojen veroprosentti on muuttunut. Jos haluat käyttää uutta veroprosenttia, palauta laskelma jaksolle ja hyväksy se uudestaan.', {}, 'tax_card')}
                <DataTable
                    isFilterable={false}
                    layout="compact"
                    data={payrolls}
                    actionsColumn={<PayslipLink route={payrollRoute} />}
                    columns={[
                        {
                            Header: _trans('Maksupäivä', {}, 'common'),
                            accessor: 'payday',
                            type: 'date',
                        },
                        {
                            Header: _trans('Jakso', {}, 'common'),
                            accessor: 'payPeriod.periodDescription',
                        },
                    ]}
                />
            </Feedback>
        </Layout.Item>
    );
};

OpenPayrollsWithOldTaxCard.propTypes = {
    payrolls: PropTypes.array,
    payrollRoute: PropTypes.string.isRequired,
};

OpenPayrollsWithOldTaxCard.defaultProps = {
    payrolls: [],
};

const PayslipLink = (props) => (
    <a href={Routing.generate(props.route, { id: props.original.payrollId })}>
        {_trans('time_registration.pay_period_selector.button_title')}
    </a>
);

PayslipLink.propTypes = {
    original: PropTypes.object,
    route: PropTypes.string.isRequired,
};

PayslipLink.defaultProps= {
    original: { payrollId: null, },
};

// Resolvoi palkkalaskelman urlin käyttäjäroolin perusteella
const resolvePayrollRoute = (userRole) => {
    if (userRole === userRoles.COMMUNE_OPERATOR) {
        return 'suoratyo_payment_payroll_detail';
    }
    if ([userRoles.EMPLOYEE, userRoles.FAMILY_CARER].includes(userRole)) {
        return 'suoratyo_payment_payslip';
    }
    return 'suoratyo_payment_details';
};
