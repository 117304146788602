/**
 * Kopsattu IncomeType.php:stä.
 */
export const incomeTypes = {
    /**
     * Tuntityöpalkka
     */
    HOURLY_WAGE: 1,

    /**
     * Kuukausipalkka
     */
    MONTHLY_SALARY: 2,

    GENERAL_COMPENSATION: 4,

    SPECIAL_COMPENSATION: 5,

    /**
     * Kiintiöylityö eli korottamaton ylityöpalkka
     */
    HOURLY_WAGE_QUOTA_OVERTIME: 7,

    /**
     * Tapaturma-ajan palkka
     */
    ACCIDENT_TIME_SALARY: 80,

    /**
     * Tapaturma-ajan palkka (alle kuukauden työsopimus)
     */
    ACCIDENT_SICK_LEAVE_LOW_SALARY: 81,

    /**
     * Tapaturma-ajan palkan vähennys
     */
    ACCIDENT_SICK_LEAVE_SUBTRACTION: 82,

    /**
     * Kelan maksama tapaturma-ajan palkka
     */
    ACCIDENT_SICKNESS_ALLOWANCE: 83,

    /**
     * palkaton tapaturma-ajan palkka
     */
    UNPAID_ACCIDENT_SICK_LEAVE: 84,

    /**
     * Sairausajan palkka
     */
    SICK_PAY: 70,

    /**
     * Sairausajan palkka
     */
    SICK_LEAVE: 9,

    /**
     * Sairausajan palkka (alle kuukauden työsopimus)
     */
    SICK_LEAVE_LOW_SALARY: 25,

    /**
     * Sairausajan palkan vähennys
     */
    SICK_LEAVE_SUBTRACTION: 26,

    /**
     * Kelan maksama sairausajan palkka
     */
    SICKNESS_ALLOWANCE: 30,

    /**
     * Palkaton sairausajan palkka
     */
    UNPAID_SICK_LEAVE: 31,

    /**
     * Varusmiespalvelus tai siviilipalvelus
     */
    MILITARY_OR_CIVILIAN_SERVICE: 32,

    /**
     * Työajanlyhennyskorvaus
     */
    SHORTENED_HOURS_COMPENSATION: 12,

    /**
     * Urakkatyöpalkka
     */
    CONTRACT_WORK: 13,

    /**
     * Lisätyö
     */
    ADDITIONAL_WORK: 15,

    /**
     * Loma-ajan palkka
     */
    ANNUAL_LEAVE: 40,

    /**
     * Etukäteen maksettu lomapäivä
     */
    ANNUAL_LEAVE_IN_ADVANCE: 41,

    /**
     * Työajanlyhennyksen palkka
     *
     * Huomaa: oli ennen IncomeType::YEARLY_LEAVE (Mattolaiturin / Maran aikaan) eli vuosivapaa.
     *
     * 04/2019 muutettu tyyppi geneerisemmin työajanlyhennystyyppiseksi
     * koska vanha Mara-toteutus on poistettu ja muutettu yleiskäyttöisemmäksi.
     */
    SHORTENED_WORK_TIME: 45,

    /**
     * Lomakorvaus
     */
    HOLIDAY_COMPENSATION: 16,

    /**
     * Lomaraha
     */
    HOLIDAY_BONUS: 60,

    HOLIDAY_COMPENSATION_IN: 10, // obsolete? 17.11.2015

    HOLIDAY_COMPENSATION_OUT: 11, // obsolete? 17.11.2015

    /**
     * Koulutus
     */
    TRAINING_WORK_HOUR: 150,

    /**
     * Ylityön korotusosa +50 %
     */
    OVERTIME_ADDITION_50: 140,

    /**
     * Ylityön korotusosa +100 %
     */
    OVERTIME_ADDITION_100: 141,

    /**
     * Iltalisä
     */
    EVENING_ALLOWANCE: 90,

    /**
     * Yölisä
     */
    NIGHT_ALLOWANCE: 91,

    /**
     * Lauantailisä
     */
    SATURDAY_ALLOWANCE: 92,

    /**
     * Sunnuntailisä
     */
    SUNDAY_ALLOWANCE: 93,

    /**
     * Arkipyhälisä
     */
    ARKIPYHALISA: 94,

    /**
     * Arkipyhäkorvaus
     */
    ARKIPYHAKORVAUS: 95,

    /**
     * Aattokorvaus
     */
    EVE_ALLOWANCE: 96,

    /**
     * Aattoiltalisä
     */
    EVE_EVENING_ALLOWANCE: 97,

    /**
     * Palkan lisä
     */
    SALARY_ADDITION: 121,

    /**
     * Palkan lisä (ei kasvata lomapottia)
     */
    SALARY_ADDITION_NO_HOLIDAYS: 122,

    /**
     * Kelan maksama tuki
     */
    KELA_ALLOWANCE: 100,

    /**
     * Muu lisäkorvaus
     */
    OTHER_REIMBURSEMENT: 110,

    /**
     * Kokopäiväraha. Veroton!
     */
    PER_DIEM: 18,

    /**
     * Osapäiväraha. Veroton!
     */
    PARTIAL_PER_DIEM: 19,

    /**
     * Ulkomaan päiväraha
     */
    FOREIGN_PER_DIEM: 21,

    /**
     * Ateriakorvaus yhdestä ateriasta. Veroton!
     */
    MEAL_COMPENSATION1: 22,

    /**
     * Ateriakorvaus kahdesta ateriasta. Veroton!
     */
    MEAL_COMPENSATION2: 23,

    /**
     * Kilometrikorvaus. Veroton!
     *
     * Huomaa: Verohallinnon säätelemä (esim. 0.41e / 2017)
     */
    KILOMETRE_ALLOWANCE: 17,

    /**
     * Matkustuskulut / "päivittäisten matkakustannusten korvaukset". Veroton!
     *
     * Huomaa: käytössä rakennusalalla (voidaan maksaa kilometrikorvauksen lisäksi).
     */
    TRAVELING_EXPENSES: 8,

    /**
     * Kulukorvaus kuittia vastaan. Veroton!
     */
    ROE_BY_RECEIPT: 20,

    /**
     * Työkalukorvaus. Veroton!
     */
    TOOL_COMPENSATION: 6,

    /**
     * Veroton korvaus. Veroton!
     */
    TAX_FREE_EXPENSE_ALLOWANCE: 115,

    /**
     * Viivästyskorko
     */
    PENALTY_INTEREST: 116,

    /**
     * Pikapalkan tuntipalkka
     */
    LITE_PER_HOUR: 50,

    /**
     * Pikapalkan palkkio
     */
    LITE_FIXED: 51,

    /**
     * Pikapalkan lomat
     */
    QP_HOLIDAY_COMPENSATION: 52,

    /**
     * Pikapalkan käteispalkka
     */
    LITE_CASH_PAYMENT: 53,

    /**
     * Keskeytyneelle työpäivälle (esim. lomautus) merkittyjä tunteja
     */
    INTERRUPTED_WORK_TIME: 111,

    /**
     * Palkan korjaus (positiivinen tai negatiivinen)
     */
    SALARY_CORRECTION: 120,

    /**
     * Ennakonpidätyksen korjaus.
     * Huomaa: ei näytetä incomerivinä palkkalaskelmalla samoin kuin näitä muita.
     * Teknisistä syistä menee kuitenkin earnedincomeksi payrollille.
     * Tämä korjaus merkitään myös payroll::tax_corrections (onkohan tarpeen olla useassa paikassa?)
     *
     * EarnedIncome::USE_CASE_TAX_CORRECTION
     */
    TAX_CORRECTION: 1201,

    /**
     * @deprecated Viimeksi käytetty palkalla jossa palkkapäivä 2019-01-10
     *
     * Nettopalkaksi lisättävät verohallinnolta palautettavat ennakonpidätykset
     *
     * EarnedIncome::USE_CASE_TAX_CORRECTION
     */
    RETURNABLE_TAX_CORRECTION: 1202,

    /**
     * Matka-ajan palkka
     */
    TRAVEL_TIME: 160,

    /**
     * Palkaton vapaapäivä (kk-palkkasopimuksella)
     */
    UNPAID_LEAVE: 130,

    /**
     * Vanhempainvapaa (palkaton)
     */
    UNPAID_MATERNITY_LEAVE: 131,

    /**
     * Vanhempainvapaa
     */
    MATERNITY_LEAVE: 138,

    /**
     * Lomautus
     */
    LAYOFF_LEAVE: 132,

    /**
     * Opintovapaa
     */
    STUDY_LEAVE: 133,

    /**
     * Muu työssäolon veroinen aika
     */
    OTHER_LEAVE: 134,

    /**
     * Tilapäinen hoitovapaa
     */
    TEMPORARY_NURSING_LEAVE: 135,

    /**
     * Tilapäinen hoitovapaa (palkaton)
     */
    UNPAID_TEMPORARY_NURSING_LEAVE: 136,

    /**
     * Palkallinen vapaa
     */
    PAID_LEAVE: 137,

    /**
     * Hoitovapaa
     */
    NURSING_LEAVE: 139,

    /**
     * Matka-ajan palkka
     */
    TRAVEL_TIME_SALARY: 160,

    /**
     * Työsuojeluvaltuutetun palkkio
     */
    SAFETY_REPRESENTATIVE_COMMISSION: 252,

    /**
     * Luottamusmiehen palkkio
     */
    SHOP_STEWARD_COMMISSION: 251,

    /**
     * Vapaaehtoinen korvaus päättämistilanteessa
     */
    FREE_ENDING_COMPENSATION: 231,

    // Nettovähennykset

    /**
     *  Asiakasvaratilille maksettava lomakorvaus
     */
    CUSTOMER_ACCOUNT_HOLIDAY_COMPENSATION: 510,

    /**
     *  Palkkaennakko
     */
    ADVANCE_PAYMENT: 520,

    /**
     * Ay-jäsenmaksu
     */
    TRADE_UNION_SUBSCRIPTION_FEE: 521,

    /**
     * Ulosotto, lisäkorvaus
     */
    DISTRAINT: 522,

    /**
     * Ulosotto, automaattinen
     */
    FORECLOSURE: 523,

    /**
     * Nettopalkasta tehtävä vähennys, joka menee maksajan varoiksi
     */
    NET_REFUND: 524,

    /**
     *  Nettovähennys
     */
    NET_REDUCTION: 525,

    /**
     * Lomien käyttö (tästä ei luoda earnedIncomeja, mutta tyypin tarvitsee
     * payPeriodDataAttributen takia).
     *
     * TODO (tuomasri): tarviiko? Poista jos ei
     */
    HOLIDAY_CONSUMPTION: 550,


    /*
     * Takuupalkan tasaus
     */
    GUARANTEED_SALARY_BALANCING: 560,

    /**
     * Provisio
     */
    PROVISION: 570,

    // Luontoisedut

    /**
     * Ravintoetu
     * Verottajan määrittelemä 75% verollinen etu. Esim. 2017 6,20-10,30e
     */
    MEAL_BENEFIT: 600,

    /**
     * Puhelinetu
     */
    PHONE_BENEFIT: 601,

    /**
     * Autoetu
     */
    CAR_BENEFIT: 602,

    /**
     * Asuntoetu
     */
    RESIDENCE_BENEFIT: 603,

    // Verottomat korvaukset

    /**
     * Ateriakorvaus (veroton)
     * Voi maksaa verottoman työntekijälle, jos ei ole maksettu päivärahaa
     */
    MEAL_COMPENSATION: 700,

    /**
     * Palkkaerittelyn lisäteksti
     */
    MESSAGE: 800,

    /**
     * Irtisanomisajan palkka
     */
    NOTICE_TIME_PAY: 810,

    /**
     * Palkallinen poissaolo - muu syy
     */
    ABSENCE_OTHER_REASON: 811,

    /**
     * Palkaton poissaolo - muu syy
     */
    UNPAID_ABSENCE_OTHER_REASON: 812,

    /**
     * Vuorotteluvapaa
     */
    ALTERNATION_LEAVE: 815,

    /**
     * Perhehoidon palkkio (vrk, tunti + kk)
     */
    FAMILY_CARE_COMPENSATION: 1002,

    /**
     * Päiväkohtainen palkkio
     */
    DAILY_COMPENSATION: 830,

    /**
     * Omaishoidon palkkio (vrk, tunti + kk)
     */
    RELATIVE_CARE_COMPENSATION: 2002,

    /**
     * Kulukorvauksen erityislisä, erikseen sovittu
     */
    FAMILY_CARE_COST_REIMBURSEMENT_CUSTOM: 1018,

    /**
     * Perhehoidon matkakustannusten korvaus
     */
    FAMILY_CARE_TRAVELLING_COMPENSATION: 1101,

    // -- Sivukulujen pseudotyypit -------------------------------------------------------------------------------------

    TYEL_EMPLOYER: -1,

    EXTERNAL_TYEL_EMPLOYER_ESTIMATE: -7,

    EXTERNAL_TYEL_EMPLOYEE_ESTIMATE: -15,

    TVR_EMPLOYER: -2,

    EXTERNAL_TVR_EMPLOYER_ESTIMATE: -8,

    EXTERNAL_TVR_EMPLOYEE_ESTIMATE: -16,

    SOCIAL_SECURITY_CONTRIBUTION: -3,

    ACCIDENT_INSURANCE: -4,

    EXTERNAL_ACCIDENT_INSURANCE_ESTIMATE: -9,

    GROUP_LIFE_INSURANCE: -5,

    EXTERNAL_GROUP_LIFE_INSURANCE_ESTIMATE: -10,

    SERVICE_CHARGES: -6,

    HOLIDAY_DEBT_RESERVATION: -11,

    HOLIDAY_DEBT_RESERVATION_USAGE: -12,

    HOLIDAY_DEBT_SIDE_EXPENSES_RESERVATION: -13,

    HOLIDAY_DEBT_SIDE_EXPENSES_RESERVATION_USAGE: -14,

    TAXES: -17,

    VAT_24: -1000,
};

/**
 * Lomapohjaiset incometypet
 */
export const holidayIncomeTypes = [
    incomeTypes.ANNUAL_LEAVE,
    incomeTypes.HOLIDAY_BONUS,
    incomeTypes.HOLIDAY_COMPENSATION,
    incomeTypes.SHORTENED_WORK_TIME,
];

/**
 * Pikapalkan palkkatyypit.
 */
export const quickPaymentIncomeTypes = [
    incomeTypes.LITE_PER_HOUR,
    incomeTypes.LITE_FIXED,
    incomeTypes.QP_HOLIDAY_COMPENSATION,
    incomeTypes.LITE_CASH_PAYMENT,
];

/**
 * Palkankorjaus, ennakonpidätyksen korjaus...
 * @type {number[]}
 */
export const correctionIncomeTypes = [
    incomeTypes.SALARY_CORRECTION,
    incomeTypes.TAX_CORRECTION,
];

// TODO: Joku järki näihin käännöksiin. Nyt on samoja käännöksiä incomeTypeissä ja salaryTypeissä.


const incomeTypeMap = {
    [incomeTypes.HOURLY_WAGE]: _trans('tes_builder.income_types.by_id.1'),
    [incomeTypes.MONTHLY_SALARY]: _trans('tes_builder.income_types.by_id.2'),
    [incomeTypes.HOURLY_WAGE_QUOTA_OVERTIME]: _trans('tes_builder.income_types.by_id.7'),
    [incomeTypes.ACCIDENT_TIME_SALARY]: _trans('tes_builder.income_types.by_id.80'),
    [incomeTypes.ACCIDENT_SICK_LEAVE_LOW_SALARY]: _trans('tes_builder.income_types.by_id.81'),
    [incomeTypes.ACCIDENT_SICK_LEAVE_SUBTRACTION]: _trans('tes_builder.income_types.by_id.82'),
    [incomeTypes.ACCIDENT_SICKNESS_ALLOWANCE]: _trans('tes_builder.income_types.by_id.83'),
    [incomeTypes.UNPAID_ACCIDENT_SICK_LEAVE]: _trans('tes_builder.income_types.by_id.84'),
    [incomeTypes.SICK_PAY]: _trans('tes_builder.income_types.by_id.70'),
    [incomeTypes.SICK_LEAVE]: _trans('tes_builder.income_types.by_id.9'),
    [incomeTypes.SICK_LEAVE_LOW_SALARY]: _trans('tes_builder.income_types.by_id.25'),
    [incomeTypes.SICK_LEAVE_SUBTRACTION]: _trans('tes_builder.income_types.by_id.26'),
    [incomeTypes.SICKNESS_ALLOWANCE]: _trans('tes_builder.income_types.by_id.30'),
    [incomeTypes.UNPAID_SICK_LEAVE]: _trans('tes_builder.income_types.by_id.31'),
    [incomeTypes.SHORTENED_HOURS_COMPENSATION]: _trans('tes_builder.income_types.by_id.12'),
    [incomeTypes.CONTRACT_WORK]: _trans('tes_builder.income_types.by_id.13'),
    [incomeTypes.ADDITIONAL_WORK]: _trans('tes_builder.income_types.by_id.15'),
    [incomeTypes.ANNUAL_LEAVE]: _trans('tes_builder.income_types.by_id.40'),
    [incomeTypes.ANNUAL_LEAVE_IN_ADVANCE]: _trans('tes_builder.income_types.by_id.41'),
    [incomeTypes.SHORTENED_WORK_TIME]: _trans('tes_builder.income_types.by_id.45'),
    [incomeTypes.HOLIDAY_COMPENSATION]: _trans('tes_builder.income_types.by_id.16'),
    [incomeTypes.HOLIDAY_BONUS]: _trans('tes_builder.income_types.by_id.60'),
    [incomeTypes.TRAINING_WORK_HOUR]: _trans('tes_builder.income_types.by_id.150'),
    [incomeTypes.OVERTIME_ADDITION_50]: _trans('tes_builder.income_types.by_id.140'),
    [incomeTypes.OVERTIME_ADDITION_100]: _trans('tes_builder.income_types.by_id.141'),
    [incomeTypes.EVENING_ALLOWANCE]: _trans('tes_builder.income_types.by_id.90'),
    [incomeTypes.NIGHT_ALLOWANCE]: _trans('tes_builder.income_types.by_id.91'),
    [incomeTypes.SATURDAY_ALLOWANCE]: _trans('tes_builder.income_types.by_id.92'),
    [incomeTypes.SUNDAY_ALLOWANCE]: _trans('tes_builder.income_types.by_id.93'),
    [incomeTypes.ARKIPYHALISA]: _trans('tes_builder.income_types.by_id.94'),
    [incomeTypes.ARKIPYHAKORVAUS]: _trans('tes_builder.income_types.by_id.95'),
    [incomeTypes.EVE_ALLOWANCE]: _trans('tes_builder.income_types.by_id.96'),
    [incomeTypes.EVE_EVENING_ALLOWANCE]: _trans('tes_builder.income_types.by_id.97'),
    [incomeTypes.SALARY_ADDITION]: _trans('tes_builder.income_types.by_id.121'),
    [incomeTypes.SALARY_ADDITION_NO_HOLIDAYS]: _trans('tes_builder.income_types.by_id.122'),
    [incomeTypes.KELA_ALLOWANCE]: _trans('tes_builder.income_types.by_id.100'),
    [incomeTypes.OTHER_REIMBURSEMENT]: _trans('tes_builder.income_types.by_id.110'),
    [incomeTypes.PER_DIEM]: _trans('tes_builder.income_types.by_id.18'),
    [incomeTypes.PARTIAL_PER_DIEM]: _trans('tes_builder.income_types.by_id.19'),
    [incomeTypes.FOREIGN_PER_DIEM]: _trans('tes_builder.income_types.by_id.21'),
    [incomeTypes.MEAL_COMPENSATION1]: _trans('tes_builder.income_types.by_id.22'),
    [incomeTypes.MEAL_COMPENSATION2]: _trans('tes_builder.income_types.by_id.23'),
    [incomeTypes.KILOMETRE_ALLOWANCE]: _trans('tes_builder.income_types.by_id.17'),
    [incomeTypes.TRAVELING_EXPENSES]: _trans('tes_builder.income_types.by_id.8'),
    [incomeTypes.ROE_BY_RECEIPT]: _trans('tes_builder.income_types.by_id.20'),
    [incomeTypes.TOOL_COMPENSATION]: _trans('tes_builder.income_types.by_id.6'),
    [incomeTypes.TAX_FREE_EXPENSE_ALLOWANCE]: _trans('tes_builder.income_types.by_id.115'),
    [incomeTypes.PENALTY_INTEREST]: _trans('tes_builder.income_types.by_id.116'),
    [incomeTypes.LITE_PER_HOUR]: _trans('tes_builder.income_types.by_id.50'),
    [incomeTypes.LITE_FIXED]: _trans('tes_builder.income_types.by_id.51'),
    [incomeTypes.QP_HOLIDAY_COMPENSATION]: _trans('tes_builder.income_types.by_id.52'),
    [incomeTypes.LITE_CASH_PAYMENT]: _trans('tes_builder.income_types.by_id.53'),
    [incomeTypes.INTERRUPTED_WORK_TIME]: _trans('tes_builder.income_types.by_id.111'),
    [incomeTypes.SALARY_CORRECTION]: _trans('tes_builder.income_types.by_id.120'),
    [incomeTypes.TAX_CORRECTION]: _trans('tes_builder.income_types.by_id.1201'),
    [incomeTypes.RETURNABLE_TAX_CORRECTION]: _trans('tes_builder.income_types.by_id.1202'),
    [incomeTypes.UNPAID_LEAVE]: _trans('tes_builder.income_types.by_id.130'),
    [incomeTypes.UNPAID_MATERNITY_LEAVE]: _trans('tes_builder.income_types.by_id.131'),
    [incomeTypes.MATERNITY_LEAVE]: _trans('tes_builder.income_types.by_id.138'),
    [incomeTypes.LAYOFF_LEAVE]: _trans('tes_builder.income_types.by_id.132'),
    [incomeTypes.STUDY_LEAVE]: _trans('tes_builder.income_types.by_id.133'),
    [incomeTypes.OTHER_LEAVE]: _trans('tes_builder.income_types.by_id.134'),
    [incomeTypes.TEMPORARY_NURSING_LEAVE]: _trans('tes_builder.income_types.by_id.135'),
    [incomeTypes.UNPAID_TEMPORARY_NURSING_LEAVE]: _trans('tes_builder.income_types.by_id.136'),
    [incomeTypes.PAID_LEAVE]: _trans('tes_builder.income_types.by_id.137'),
    [incomeTypes.NURSING_LEAVE]: _trans('tes_builder.income_types.by_id.139'),
    [incomeTypes.TRAVEL_TIME_SALARY]: _trans('tes_builder.income_types.by_id.160'),
    [incomeTypes.FREE_ENDING_COMPENSATION]: _trans('tes_builder.income_types.by_id.231'),
    [incomeTypes.CUSTOMER_ACCOUNT_HOLIDAY_COMPENSATION]: _trans('tes_builder.income_types.by_id.510'),
    [incomeTypes.ADVANCE_PAYMENT]: _trans('tes_builder.income_types.by_id.520'),
    [incomeTypes.TRADE_UNION_SUBSCRIPTION_FEE]: _trans('tes_builder.income_types.by_id.521'),
    [incomeTypes.DISTRAINT]: _trans('tes_builder.income_types.by_id.522'),
    [incomeTypes.FORECLOSURE]: _trans('tes_builder.income_types.by_id.523'),
    [incomeTypes.NET_REFUND]: _trans('tes_builder.income_types.by_id.524'),
    [incomeTypes.NET_REDUCTION]: _trans('tes_builder.income_types.by_id.525'),
    [incomeTypes.GUARANTEED_SALARY_BALANCING]: _trans('tes_builder.income_types.by_id.560'),
    [incomeTypes.PROVISION]: _trans('tes_builder.income_types.by_id.570'),
    [incomeTypes.MEAL_BENEFIT]: _trans('tes_builder.income_types.by_id.600'),
    [incomeTypes.PHONE_BENEFIT]: _trans('tes_builder.income_types.by_id.601'),
    [incomeTypes.CAR_BENEFIT]: _trans('tes_builder.income_types.by_id.602'),
    [incomeTypes.RESIDENCE_BENEFIT]: _trans('tes_builder.income_types.by_id.603'),
    [incomeTypes.MEAL_COMPENSATION]: _trans('tes_builder.income_types.by_id.700'),
    [incomeTypes.MESSAGE]: _trans('tes_builder.income_types.by_id.800'),
    [incomeTypes.TRAVEL_TIME]: _trans('tes_builder.income_types.by_id.160'),
    [incomeTypes.NOTICE_TIME_PAY]: _trans('tes_builder.income_types.by_id.810'),
    [incomeTypes.ABSENCE_OTHER_REASON]: _trans('tes_builder.income_types.by_id.811'),
    [incomeTypes.UNPAID_ABSENCE_OTHER_REASON]: _trans('tes_builder.income_types.by_id.812'),
    [incomeTypes.SAFETY_REPRESENTATIVE_COMMISSION]: _trans('salary.shop_steward_commission'),
    [incomeTypes.SHOP_STEWARD_COMMISSION]: _trans('salary.safety_representative_commission'),
    [incomeTypes.MILITARY_OR_CIVILIAN_SERVICE]: _trans('tes_builder.income_types.by_id.32'),
    [incomeTypes.ALTERNATION_LEAVE]: _trans('Vuorotteluvapaa', {}, 'extract'),
    [incomeTypes.FAMILY_CARE_COST_REIMBURSEMENT_CUSTOM]: _trans('Perhehoidon kulukorvauksen erityislisä, erikseen sovittu (pv)', {}, 'extract'),
    [incomeTypes.FAMILY_CARE_COMPENSATION]: _trans('Perhehoidon palkkio', {}, 'extract'),
    [incomeTypes.RELATIVE_CARE_COMPENSATION]: _trans('Omaishoidon palkkio', {}, 'extract'),
    [incomeTypes.FAMILY_CARE_TRAVELLING_COMPENSATION]: _trans('Matkakustannusten korvaus', {}, 'extract'),
    // -- Sivukulujen pseudotyypit -------------------------------------------------------------------------------------
    [incomeTypes.TYEL_EMPLOYER]: _trans('Työnantajan TyEL, maksettu Oiman kautta', {}, 'extract'),
    [incomeTypes.EXTERNAL_TYEL_EMPLOYER_ESTIMATE]: _trans('TyEL maksut (siirtovelka)', {}, 'extract'),
    [incomeTypes.EXTERNAL_TYEL_EMPLOYEE_ESTIMATE]: _trans('Työntekijän TyEL (siirtovelka)', {}, 'extract'),
    [incomeTypes.TVR_EMPLOYER]: _trans('Työnantajan Työttömyysvakuutusmaku Oiman kautta', {}, 'extract'),
    [incomeTypes.EXTERNAL_TVR_EMPLOYER_ESTIMATE]: _trans('Työttömyysvakuutusmaksu (siirtovelka)', {}, 'extract'),
    [incomeTypes.EXTERNAL_TVR_EMPLOYEE_ESTIMATE]: _trans('Työntekijän työttömyysvakuutusmaksu (siirtovelka)', {}, 'extract'),
    [incomeTypes.SOCIAL_SECURITY_CONTRIBUTION]: _trans('Sosiaaliturvamaksu', {}, 'extract'),
    [incomeTypes.ACCIDENT_INSURANCE]: _trans('Työtapaturmavakuutusmaksu', {}, 'extract'),
    [incomeTypes.EXTERNAL_ACCIDENT_INSURANCE_ESTIMATE]: _trans('Työtapaturmavakuutusmaksu (siirtovelka)', {}, 'extract'),
    [incomeTypes.GROUP_LIFE_INSURANCE]: _trans('Ryhmähenkivakuutusmaksu', {}, 'extract'),
    [incomeTypes.EXTERNAL_GROUP_LIFE_INSURANCE_ESTIMATE]: _trans('Ryhmähenkivakuutusmaksu (siirtovelka)', {}, 'extract'),
    [incomeTypes.SERVICE_CHARGES]: _trans('Oiman palvelumaksut', {}, 'extract'),
    [incomeTypes.HOLIDAY_DEBT_RESERVATION]: _trans('Lomapalkkojen jaksotus', {}, 'extract'),
    [incomeTypes.HOLIDAY_DEBT_RESERVATION_USAGE]: _trans('Maksettujen lomapalkkojen jaksotus', {}, 'extract'),
    [incomeTypes.HOLIDAY_DEBT_SIDE_EXPENSES_RESERVATION]: _trans('Lomapalkkojen sivukulujen jaksotus', {}, 'extract'),
    [incomeTypes.HOLIDAY_DEBT_SIDE_EXPENSES_RESERVATION_USAGE]: _trans('Maksettujen lomapalkkojen sivukulujen jaksotus', {}, 'extract'),
    [incomeTypes.TAXES]: _trans('Ennakonpidätykset', {}, 'extract'),
    [incomeTypes.VAT_24]: _trans('ALV 24 %', {}, 'extract'),
};

export function translateIncomeType(incomeType) {
    return incomeType in incomeTypeMap ? incomeTypeMap[incomeType] : `${_trans('Tuntematon', {}, 'common')} (${incomeType})`;
}

export const incomeUseCases = {
    USE_CASE_EARNED_INCOME: 1,

    /**
     * Palkankorjauksen Perusteeton etu
     * Käytetään kun on luotu perusteeton etu palkankorjauksen kautta.
     * Käytetään koska pitää pystyä kahdella tavalla hoitamaan perusteeton etu.
     * Vanhassa toteutuksessa perusteeton etu määrä vähennetään ilmoitettavasta palkasta.
     * Uudessa tavassa ei vähennetä mitään. Summat menee semmoisenaan.
     */
    USE_CASE_UNJUST_ENRICHMENT_CORRECTION: 201,

    /**
     * Merkitään kun työntekijän palkkaa korjataan isommaksi
     * ja tulolaji jota ei ole maksettu kyseisessä palkassa
     */
    USE_CASE_NON_RECEIVED_GAIN: 300,
};

export const deptGeneratingUseCases = [
    incomeUseCases.USE_CASE_UNJUST_ENRICHMENT_CORRECTION,
    incomeUseCases.USE_CASE_NON_RECEIVED_GAIN,
];

export const additionalSalaryTypes = [
    incomeTypes.SALARY_ADDITION,
    incomeTypes.SALARY_ADDITION_NO_HOLIDAYS,
];
