import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/Button';
import Popover from 'shared/components/Popover';
import Separator from 'shared/components/Separator';

/**
 * Filtterin valinta checkboxlistalta.
 * Sisältää myös valintojen nollauksen.
 * @param filterValue
 * @param setFilter
 * @param filterOptions
 * @param Header
 * @param placeholder
 * @param column
 * @param isSearchable
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckboxFilter = ({ column: { filterValue, setFilter, filterOptions, Header, placeholder }, column }, isSearchable) => {
    const inputElement = useRef(null);
    const optionLabelKey = column.optionLabelKey ?? 'label';
    const optionValueKey = column.optionValueKey ?? 'value';

    const selectedFilters = filterValue ?? [];
    const selectedFilterCount = selectedFilters.length;
    const [searchValue, setSearchValue] = useState('');

    // Ei valittu mitään => Kaikki
    let dropdownText = _trans('text.all');

    // Jos vain yksi valinta => näytä se
    if (selectedFilterCount === 1) {
        const selectedValue = filterOptions.find((filterOption) =>
            filterOption.value.toString() === selectedFilters[0].toString());
        if (selectedValue) {
            dropdownText = selectedValue.label ?? '-';
        }
    } else if (selectedFilterCount > 1 && selectedFilterCount < filterOptions.length) {
        // Monta valintaa: kerro montako valittu
        dropdownText = _trans('dropdown.chosen', { count: selectedFilters.length });
    }

    return (
        <Popover minWidth={200} position="bottom-start" isFullWidth isArrowVisible={false} maxHeight={480}>
            <div className="u-position-relative u-1/1">
                <button className="c-dropdown u-1/1 u-text-left u-text-truncate">
                    {dropdownText}
                </button>
            </div>
            <Fragment>
                <div className="u-position-relative u-1/1">
                    <input
                        type="text"
                        ref={inputElement}
                        aria-label={Header}
                        value={searchValue}
                        onChange={(event) => {
                            setSearchValue(event.target.value); // Undefined poistaa filtteröinnin kokonaan
                        }}
                        placeholder={placeholder}
                        className="u-1/1"
                    />
                    {searchValue && searchValue !== '' && (
                        <Button
                            flat
                            mdIcon="clear"
                            modifierClass="o-pin o-pin--right o-pin--top"
                            title={_trans('Tyhjennä kenttä.', {}, 'common')}
                            onClick={() => {
                                setSearchValue('');
                                // Tekstin tyhjennyksen jälkeen takaisin filtterikenttään
                                inputElement.current.focus();
                            }}
                        />
                    )}
                </div>
                <div className="u-text-left">
                    <Button
                        mdIcon="clear"
                        modifierClass="u-padding-horizontal-none"
                        disabled={selectedFilterCount === 0}
                        onClick={() => setFilter([])}
                        size="small"
                        flat
                        iconSize="small"
                    >
                        {_trans('multi_select.clear_selections')}
                    </Button>
                    <Separator/>
                    {filterOptions
                        .filter((option) => (
                            isSearchable
                                ? option[optionLabelKey]?.toLowerCase().startsWith(searchValue?.toString().toLowerCase())
                                : true
                        ))
                        .map((option, i) => (
                            <div key={i}>
                                <label className="u-text-no-wrap">
                                    <input
                                        type="checkbox"
                                        value={option[optionValueKey]}
                                        checked={filterValue && filterValue.indexOf(String(option.value)) > -1}
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            const values = filterValue ? filterValue : [];
                                            if (event.target.checked) {
                                                setFilter([ ...values, value ]);
                                            } else {
                                                setFilter(values.filter((filterValue) => filterValue !== value));
                                            }
                                        }}
                                    />
                                    {option[optionLabelKey]}
                                </label>
                            </div>
                        ))}
                </div>
            </Fragment>
        </Popover>
    );
};

CheckboxFilter.propTypes = {
    column: PropTypes.shape({
        filterValue: PropTypes.string,
        setFilter: PropTypes.func.isRequired,
        optionLabelKey: PropTypes.string,
        optionValueKey: PropTypes.string,
        filterOptions: PropTypes.array.isRequired,
        filterMethod: PropTypes.func,
        Header: PropTypes.string,
        placeholder: PropTypes.string,
    }).isRequired,
    isSearchable: PropTypes.bool,
};

CheckboxFilter.defaultProps = {
    isSearchable: false,
};
