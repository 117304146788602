import { customCellTypes } from '../customCellTypes';
import { customFilterTypes } from '../customFilterTypes';
import { DropdownFilter } from './DropdownFilter';
import { CheckboxFilter } from './CheckboxFilter';

// Tyypillisin filtteröintitapa
const defaultFilterMethod = (filterValue, value) => value.toString().toLowerCase().includes(filterValue.toLowerCase());

/**
 * Oletusfiltteri checkbox-tyypillä.
 * Vertailee joko useampaa valittua filtteriarvoa yhteen solun arvoon tai taulukkoon.
 * @param rows
 * @param id
 * @param filterValue
 * @returns {boolean|boolean|*}
 */
const defaultCheckboxFilterMethod = (rows, id, filterValue) => {
    const values = filterValue ?? [];
    if (values.length === 0) return rows;

    return rows.filter((row) => {
        const value = row.values[id];
        return (
            values.includes(String(value))
            || (Array.isArray(value) && value.some((item) => values.includes(String(item))))
        );
    });
};

/**
 * Oletusfiltteri droppareille.
 * @param rows
 * @param id
 * @param filterValue
 * @returns {*}
 */
const defaultDropdownFilterMethod = (rows, id, filterValue) => rows.filter((row) => String(filterValue) === String(row.values[id]));

/**
 * Oletusfiltteri date-tyypillä.
 * Vertailee joko useampaa valittua filtteriarvoa yhteen solun arvoon tai taulukkoon.
 * @param filterValue
 * @param value
 * @returns {boolean|boolean|*}
 */
/*const defaultDateFilterMethod = (filterValue, value) => {
    //console.log(filterValue, value, isDateTimeType);
    const filters = filterValue.split(' ');
    const isDateRange = filters.length === 3 && filters[1] === '&';
    console.log(isDateRange, filters, value);

    return value.toString().toLowerCase().includes(filterValue.toLowerCase());
};*/

/**
 * Solun renderöintitapa tyypin mukaan.
 * @param column
 */
const getCustomFilter = (column) => {
    const defaultValue = column.defaultValue ?? '';
    const hasFilterMethodFunction = typeof column.filterMethod === 'function';

    switch (column.type) {
        case customCellTypes.CURRENCY:
            return {
                filter: (rows, id, filterValue) => rows.filter((row) => {
                    const value = _currency(row.values[id]);
                    // Käyttäjä voi hakea joko formatoitua valuutta-arvoa tai alkuperäistä
                    return defaultFilterMethod(filterValue, value) || defaultFilterMethod(filterValue, value.toString());
                })
            };

        case customCellTypes.DATE:
        case customCellTypes.DATETIME: {
            const isDateTimeType = column.type === customCellTypes.DATETIME;
            return {
                width: column.width ?? 180,
                filter: (rows, id, filterValue) => rows.filter((row) => {
                    const value = row.values[id];
                    // Filtteröidään joko lokalisoituun muotoon muutettu arvo tai oletusarvo.
                    return defaultFilterMethod(
                        filterValue,
                        value !== ''
                            ? _toLocaleDate(value, '', isDateTimeType ? _dateTimeFormat : _dateFormat)
                            : defaultValue
                    );
                }),
                //Filter: (column) => DateFilter(column, true),
            };
        }

        // Lokalisaation mukainen numeron formatointi
        case customCellTypes.NUMBER:
            return {
                filter: (rows, id, filterValue) => rows.filter((row) => {
                    const value = row.values[id];
                    // Käyttäjä voi hakea joko formatoitua valuutta-arvoa tai alkuperäistä
                    return defaultFilterMethod(filterValue, value) || defaultFilterMethod(filterValue, value.toString());
                })
            };

        default:
            return hasFilterMethodFunction
                ? {
                    filter: column.filterMethod,
                }
                : undefined;
    }
};

/**
 * Kustomoitu filtterin tyyppi, esim. alasvetovalikko.
 * @param column
 */
const getCustomFilterType = (column) => {
    const hasFilterMethodFunction = typeof column.filterMethod === 'function';

    switch (column.filterType) {
        case customFilterTypes.DROPDOWN:
            return {
                filter: hasFilterMethodFunction ? column.filterMethod : defaultDropdownFilterMethod,
                Filter: DropdownFilter,
            };

        case customFilterTypes.CHECKBOX: {
            return {
                // Annettu oma filtteri funktio, käytetään sitä
                filter: hasFilterMethodFunction ? column.filterMethod : defaultCheckboxFilterMethod,
                Filter: CheckboxFilter,
            };
        }

        case customFilterTypes.SEARCHABLE_CHECKBOX: {
            return {
                // Annettu oma filtteri funktio, käytetään sitä
                filter: hasFilterMethodFunction ? column.filterMethod : defaultCheckboxFilterMethod,
                Filter: (column) => CheckboxFilter(column, true),
            };
        }

        default:
            return {};
    }
};

/**
 * Kustomoidut filtteröintimetodit.
 * Ehkä nämäkin voisi jatkossa palastella vielä pienemmiksi...
 * @param columns
 */
export const setCustomFilters = (columns) => columns.map((column) => ({
    ...column,
    ...getCustomFilter(column),
    ...getCustomFilterType(column),
}));

