import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { select } from '@rematch/select';
import { FormField, SwitchField } from 'shared/components/Formik';
import { resolveStyles } from 'shared/ReForm/utils/resolveStyles';

/**
 * Vaaditaanko allekirjoitusta sopimusmuutoksesta.
 * @constructor
 */
const ReSignatureSwitch = ({ name, label, style }) => {
    const hasPreviousSignatures = useSelector(select.contract.hasPreviousSignatures);

    // Ihan uudella sopparilla ei luonnollisesti yhtään aiempaa allekirjoitusta löydy joten turha näyttää
    if (! hasPreviousSignatures) return null;

    return (
        <FormField label={_trans('Sopimuksen uudelleen allekirjoitus', {}, 'jobContract')}>
            <label className="o-form__text" style={resolveStyles(style)}>
                <SwitchField id="resignatureSwitch" name={name}>
                    {label}
                </SwitchField>
            </label>
        </FormField>
    );
};

ReSignatureSwitch.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    style: PropTypes.object,
};

ReSignatureSwitch.defaultProps = {
    style: {},
};

export default ReSignatureSwitch;
