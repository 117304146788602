import React, { Fragment } from 'react';
import { select } from '@rematch/select';
import { useSelector } from 'react-redux';
import { Feedback, Badge, Panel } from 'shared/components';
import { TaxCardForm } from 'shared/TaxCard/containers/TaxCardForm';
import taxCardShape from 'shared/shapes/taxCard';
import { taxCardTypes, translateTaxCardType } from 'shared/constants/taxCardTypes';
import { badgeTypes } from 'shared/components/Badge';
import { TaxCardFields } from 'shared/TaxCard/containers/TaxCardFields';
import { SourceTaxCardFields } from 'shared/TaxCard/containers/SourceTaxCardFields';
import { NoTaxCardFields } from 'shared/TaxCard/containers/NoTaxCardFields';
import { PanelActionItems } from 'shared/TaxCard/containers/PanelActionItems';

export const TaxCard = ({ taxCard }) => {
    const isEditMode = useSelector(select.taxCards.isEditMode);
    const isReadOnly = useSelector((state) => select.taxCards.isReadOnly(state, taxCard.taxCardId));
    const activeTaxCard = useSelector(select.taxCards.getActiveTaxCardId);
    const selectedTaxCardId = useSelector(select.taxCards.getSelectedTaxCardId);
    const relatedContracts = useSelector(select.taxCards.getRelatedContracts);

    if (taxCard.length === 0 && ! activeTaxCard && ! isEditMode) {
        return (
            <Feedback
                type="error"
                message={_trans('tax_card.info.no_tax_card_wo_link')}
                modifierClass="u-margin-bottom"
            />
        );
    }

    const normalTaxCardTypes = [
        taxCardTypes.TYPE_B,
        taxCardTypes.COMPENSATION_TAX_CARD_FAMILY_CARE,
        taxCardTypes.COMPENSATION_TAX_CARD_RELATIVE_CARE,
        taxCardTypes.COMPENSATION_TAX_CARD_PERSONAL,
    ];

    const modifiedInfo = () => {
        if (taxCard.created && taxCard.frontendModifiedDate) {
            return (
                <div className="u-text-italic u-font-size-12 u-margin-top-small o-stack o-stack--right">
                    {_trans('text.created')} {_toLocaleDate(taxCard.created)}, {_trans('text.edited')} {_toLocaleDate(taxCard.frontendModifiedDate)}
                </div>
            );
        }

        return (
            <div className="u-text-italic u-font-size-12 u-margin-top-small o-stack o-stack--right">
                {_trans('text.created')} {_toLocaleDate(taxCard.created)}
            </div>
        );
    };

    return (
        <Panel
            heading={isEditMode
                ? selectedTaxCardId ? _trans('tax_card.edit_text') : _trans('tax_card.button.new')
                : translateTaxCardType(taxCard.type)}
            actionItems={<PanelActionItems taxCardId={taxCard?.taxCardId ?? taxCard.id} isOverrideVeroFetch={taxCard?.isOverrideVeroFetch} />}
        >
            {relatedContracts && relatedContracts.length > 0 && (! isEditMode) &&
                <Feedback
                    type="warning"
                    isDismissable
                    message={_trans('Huomioithan työntekijän muut sopimukset, mikäli muutat verokorttia: ', {}, 'tax_card') + relatedContracts}
                    modifierClass="u-margin-bottom"
                />
            }

            {/* Käytetään formia vain, jos ollaan muokkauksessa */}
            {isEditMode
                ? <TaxCardForm />
                : <Fragment>
                    {/* Verokortin infot */}
                    { renderFeedback(isReadOnly, taxCard.isVeroFetched, Object.keys(taxCard?.originalVeroTaxCard ?? {}).length > 0) }
                    {/* Verokortin tietokentät */}
                    <div className="o-form o-form--responsive u-margin-top">
                        {normalTaxCardTypes.includes(taxCard.type) && <TaxCardFields taxCard={taxCard} />}
                        {taxCard.type === taxCardTypes.TAX_AT_SOURCE && <SourceTaxCardFields taxCard={taxCard} />}
                        {taxCard.type === taxCardTypes.NO_TAX_CARD && <NoTaxCardFields taxCard={taxCard} />}
                    </div>
                    {/* Lisätieto badget */}
                    <div className="u-margin-top o-stack o-stack--right">
                        {taxCard.isVeroFetched && ! taxCard.isUpdatedVeroFetchedTaxCard&& <Badge
                            isBall={false}
                            value={_trans('tax_card.details.vero_fetched_date_long', { date: _toLocaleDate(taxCard.originalVeroFetchDate ) })}
                            type={badgeTypes.NEUTRAL}
                        />}
                        {taxCard.authorDetails && <Badge
                            isBall={false}
                            value={_trans('tax_card.details.added_by_long', { addedBy:  taxCard.authorDetails })}
                            type={badgeTypes.NEUTRAL}
                            modifierClass="u-margin-left-tiny"
                        />}
                    </div>
                    {/* Lisäys/muokkaus pvm*/}
                    {modifiedInfo()}
                </Fragment>
            }

        </Panel>
    );
};

TaxCard.propTypes = {
    taxCard: taxCardShape.isRequired,
};

const renderFeedback = (isReadOnly, isVeroFetched, hasOriginalTaxCard) => {
    if (hasOriginalTaxCard) {
        return (
            <Feedback type="info">
                {_trans('tax_card.form.messages.original_vero_fetched_tax_card')}
                {isReadOnly && _transMd('tax_card.form.messages.tax_card_used_editabale')}
            </Feedback>
        );
    } else if (isVeroFetched) {
        return (
            <Feedback type="info">
                {_trans('tax_card.details.vero_fetched_info')}
                {isReadOnly && _transMd('tax_card.form.messages.tax_card_used_editabale')}
            </Feedback>
        );
    } else {
        return isReadOnly && <Feedback type="info" message={_trans('tax_card.form.messages.tax_card_used_read_only')}/>;
    }
};
