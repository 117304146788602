
export const creditTransferPaymentTypes = {
    WAGES: 1,

    WAGES_TEST: 2,

    PERIODIC_TAX_RETURN: 3,

    PERIODIC_TAX_RETURN_TEST: 4,

    INSURANCE: 5,

    INSURANCE_TEST: 6,

    TYEL: 7,

    TYEL_PAYMENT: 8,

    HOLIDAY_PAY: 9,

    TVR: 10,

    TVR_EQUALISATION_FIX: 11,

    TVR_PAYMENT: 12,

    TAX_RETURN_EXPORT_ITEM: 13,

    SOCIAL_SECURITY_CONTRIBUTION: 14,

    CUSTOMER_INVOICE: 15,

    TYTAL_INVOICE: 16,

    GENERIC_INVOICE: 17,

    QUARTER_TVR: 18,

    NOPSA: 20,

    FORECLOSURE: 30,

    TRADE_UNION_SUBSCRIPTION: 40,

    OFFLINE_PAYMENT_SIDE_COST: 50,

    UNDEFINED: 90,

    DEFAULT_TYPE: 100,

    SALA_PAYMENT: 101,

    OTHER_PAYMENT: 102,

    COMMUNE_REFUND: 200,

    ONLINE_PAYMENT_REFUND: 201,
};

export const translateCreditTransferPaymentType = (type) => {
    switch (type) {
        case creditTransferPaymentTypes.WAGES:
            return _trans('credit_transfer.payments.types.wages');
        case creditTransferPaymentTypes.HOLIDAY_PAY:
            return _trans('credit_transfer.payments.types.holiday_pay');
        case creditTransferPaymentTypes.PERIODIC_TAX_RETURN:
        case creditTransferPaymentTypes.TAX_RETURN_EXPORT_ITEM:
            return _trans('credit_transfer.payments.types.periodic_tax_return');
        case creditTransferPaymentTypes.TYEL:
            return _trans('credit_transfer.payments.types.tyel');
        case creditTransferPaymentTypes.TVR:
        case creditTransferPaymentTypes.TVR_PAYMENT:
        case creditTransferPaymentTypes.QUARTER_TVR:
            return _trans('credit_transfer.payments.types.tvm');
        case creditTransferPaymentTypes.UNDEFINED:
            return _trans('credit_transfer.payments.types.undefined');
        case creditTransferPaymentTypes.OTHER_PAYMENT:
            return 'OTHER';
        case creditTransferPaymentTypes.SALA_PAYMENT:
            return 'SALA';
        case creditTransferPaymentTypes.FORECLOSURE:
            return _trans('credit_transfer.payments.types.foreclosure');
        case creditTransferPaymentTypes.TRADE_UNION_SUBSCRIPTION:
            return _trans('credit_transfer.payments.types.trade_union');
        case creditTransferPaymentTypes.COMMUNE_REFUND:
            return _trans('Palautus', {}, 'credit_transfer');
        case creditTransferPaymentTypes.ONLINE_PAYMENT_REFUND:
            return _trans('E-Maksun palautus', {}, 'credit_transfer');
        case creditTransferPaymentTypes.SOCIAL_SECURITY_CONTRIBUTION:
            return _trans('credit_transfer.payments.types.ssc');
        case creditTransferPaymentTypes.CUSTOMER_INVOICE:
            return _trans('credit_transfer.payments.types.customer_invoice');
        case creditTransferPaymentTypes.TYTAL_INVOICE:
            return _trans('credit_transfer.payments.types.tytal_invoice');
        case creditTransferPaymentTypes.GENERIC_INVOICE:
            return _trans('credit_transfer.payments.types.generic_invoice');
        case creditTransferPaymentTypes.OFFLINE_PAYMENT_SIDE_COST:
            return _trans('Palkkojen sivukulut', {}, 'credit_transfer');
        default:
            return `${_trans('Tuntematon', {}, 'credit_transfer')} (${type})`;
    }
};
