export const salaryTypes = {
    PER_HOUR: 1,
    PER_MONTH: 2,
    CONTRACT: 3,
    ADDITIONAL_WORK: 4,
    PER_DAY: 50,

    KILOMETRE_ALLOWANCE: 5,
    PER_DIEM: 6,
    PARTIAL_PER_DIEM: 7,
    ROE_BY_RECEIPT: 8,

    EVENING_ALLOWANCE: 9,
    NIGHT_ALLOWANCE: 10,
    SATURDAY_ALLOWANCE: 11,
    SUNDAY_ALLOWANCE: 12,

    LITE_FIXED: 20,
    LITE_PER_HOUR: 21,
    LITE_CASH_PAYMENT: 22,
    LITE_CASH_FIXED: 40,
    LITE_CASH_PER_HOUR: 41
};

const salaryTypeMap = {
    [salaryTypes.PER_HOUR]: (domain) => Translator.trans('constant.salary_type.per_hour', {}, domain),
    [salaryTypes.PER_MONTH]: (domain) => Translator.trans('constant.salary_type.per_month', {}, domain),
    [salaryTypes.CONTRACT]: (domain) => Translator.trans('constant.salary_type.contract', {}, domain),
    [salaryTypes.ADDITIONAL_WORK]: (domain) => Translator.trans('constant.salary_type.additional_work', {}, domain),
    [salaryTypes.PER_DAY]: (domain) => Translator.trans('constant.salary_type.per_day', {}, domain),
    [salaryTypes.KILOMETRE_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.kilometre_allowance', {}, domain),
    [salaryTypes.PER_DIEM]: (domain) => Translator.trans('constant.salary_type.per_diem', {}, domain),
    [salaryTypes.PARTIAL_PER_DIEM]: (domain) => Translator.trans('constant.salary_type.partial_per_diem', {}, domain),
    [salaryTypes.ROE_BY_RECEIPT]: (domain) => Translator.trans('constant.salary_type.roe_by_receipt', {}, domain),
    [salaryTypes.EVENING_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.evening_allowance', {}, domain),
    [salaryTypes.NIGHT_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.night_allowance', {}, domain),
    [salaryTypes.SATURDAY_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.saturday_allowance', {}, domain),
    [salaryTypes.SUNDAY_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.sunday_allowance', {}, domain),
    [salaryTypes.LITE_FIXED]: (domain) => Translator.trans('constant.salary_type.lite_fixed', {}, domain),
    [salaryTypes.LITE_PER_HOUR]: (domain) => Translator.trans('constant.salary_type.lite_per_hour', {}, domain),
    [salaryTypes.LITE_CASH_PAYMENT]: (domain) => Translator.trans('constant.salary_type.lite_cash_payment', {}, domain),
    [salaryTypes.LITE_CASH_FIXED]: (domain) => Translator.trans('constant.salary_type.lite_cash_fixed', {}, domain),
    [salaryTypes.LITE_CASH_PER_HOUR]: (domain) => Translator.trans('constant.salary_type.lite_cash_per_hour', {}, domain),
};

const salaryTypeUnitMap = {
    [salaryTypes.PER_HOUR]: (domain) => Translator.trans('constant.salary_type.euro_per_hour', {}, domain),
    [salaryTypes.PER_MONTH]: (domain) => Translator.trans('constant.salary_type.euro_per_month', {}, domain),
    [salaryTypes.CONTRACT]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.ADDITIONAL_WORK]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.PER_DAY]: (domain) => Translator.trans('constant.salary_type.euro_per_day', {}, domain),
    [salaryTypes.KILOMETRE_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.PER_DIEM]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.PARTIAL_PER_DIEM]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.ROE_BY_RECEIPT]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.EVENING_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.NIGHT_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.SATURDAY_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.SUNDAY_ALLOWANCE]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.LITE_FIXED]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.LITE_PER_HOUR]: (domain) => Translator.trans('constant.salary_type.euro_per_hour', {}, domain),
    [salaryTypes.LITE_CASH_PAYMENT]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.LITE_CASH_FIXED]: (domain) => Translator.trans('constant.salary_type.euro', {}, domain),
    [salaryTypes.LITE_CASH_PER_HOUR]: (domain) => Translator.trans('constant.salary_type.euro_per_hour', {}, domain),
};

export function translateSalaryType(type, domain = 'contract') {
    if (type === '') {
        return _trans('Ei valintaa', {}, 'common');
    }
    domain = window._translationDomain ? window._translationDomain : domain;
    return type in salaryTypeMap ? salaryTypeMap[type](domain) : _trans('Tuntematon', {}, 'common');
}

export function translateSalaryUnit(type, domain = 'contract') {
    if (type === '') {
        return _trans('Ei valintaa', {}, 'common');
    }
    return type in salaryTypeUnitMap ? salaryTypeUnitMap[type](domain) : _trans('Tuntematon', {}, 'common');
}

// SubSalaarien typet

// Normaali palkka / palkkio
export const TYPE_NORMAL_SALARY = 1;

// Joku peruskulukorvauksista, sub_type tarkentaa
export const TYPE_COST_REIMBURSEMENT = 2;

// Peruskulukorvaus: se tavallinen
export const SUB_TYPE_COST_REIMBURSEMENT_NORMAL = 'A';

// Peruskulukovaus: erityislisä
export const SUB_TYPE_COST_REIMBURSEMENT_CUSTOM = 'B';

// Kulukorvauksen erityislisä
export const SUB_TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE = 'C';

export const resolveTitleBySubType = (subType) => {
    switch (subType) {
        case SUB_TYPE_COST_REIMBURSEMENT_NORMAL:
            return _trans('contract.form.salary.cost_reimbursements.normal.label');
        case SUB_TYPE_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE:
            return _trans('contract.form.salary.cost_reimbursements.special_allowance.label');
        case SUB_TYPE_COST_REIMBURSEMENT_CUSTOM:
            return _trans('contract.form.salary.cost_reimbursements.cost_reimbursement_custom.label');
        default:
            return '-';
    }
};
