import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useFocusTrap, useClickOutside, useScrollLock, useMergedRef } from '@mantine/hooks';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import { PopoverContent } from './PopoverContent';
import utils from 'shared/utils/commonUtils';
import { positions } from 'shared/constants';
import MDIcon from 'shared/components/MDIcon';

// Popoverin custom-tyylit
const popOverTypes = [
    'default',
    'notifications',
    'list',
    'clear',
];

/**
 * Piirtää tason muiden elementtien päälle. Tasolla voi olla mitä tahansa. Käyttötarkoituksena
 * pienen(!) lisäinfon näyttäminen, valintojen esittäminen, useamman toiminnon piilottaminen triggerin alle yms.
 * Käyttää aria-describedby ja role-attribuutteja.
 */
const Popover = ({
    children,
    position,
    isOpen,
    openOn,
    onOpen,
    onClose,
    onMount,
    onUnMount,
    onKeyDown,
    describedById,
    hasContentPadding,
    popoverType,
    width,
    minWidth,
    maxWidth,
    maxHeight,
    title,
    isArrowVisible,
    triggerModifierClass,
}) => {
    const [, setScrollLocked] = useScrollLock();
    const [isStateOpen, setStateIsOpen] = useState(isOpen);
    // const focusTrapRef = useFocusTrap(true);

    const focusTrapRef = useFocusTrap(true);

    const [uuid, setUuid] = useState(null);

    /**
     * Callback esim. fokuksen kutsumiseen Popoverin sisällä.
     */
    useEffect(() => {
        onMount();

        // Jos describedById on annettu tulee sama id olla esitelty tämän komponentin ulkopuolella.
        // Muutoin generoidaan jokin satunnainen uuid yhdistämään PopOverin ensimmäinen ja viimeinen child.
        setUuid(describedById ? describedById : utils.generateUuid());

        return () => {
            onUnMount();
        };
    }, [describedById, onMount, onUnMount]);

    useEffect(() => {
        // Onko annettu arvo jokin muu kuin null = halutaan eksplisiittisesti joko sulkea tai avata Popover.
        if (isOpen !== null) {
            setStateIsOpen(isOpen);
            // Vierityslukko
            setScrollLocked(isOpen);
        }
    }, [isOpen, setScrollLocked]);

    /**
     * Sulje Popover ESC:stä.
     * @param event
     */
    const onPopoverKeyDown = (event) => {
        if (event.keyCode === 27) onClosePopover();
        onKeyDown(event);
    };

    /**
     * Aseta näppäinkuuntelija kun Popover on auki.
     */
    const onOpenPopover = () => {
        document.addEventListener('keydown', onPopoverKeyDown, false);
        onOpen();
        setStateIsOpen(true);
    };

    /**
     * Poista kuuntelija kun Popover suljetaan.
     */
    const onClosePopover = () => {
        document.removeEventListener('keydown', onPopoverKeyDown, false);
        onClose();
        setStateIsOpen(false);
    };

    const onTogglePopover = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (!isStateOpen) {
            onOpenPopover();
        } else {
            onClosePopover();
        }
    };

    const getTriggerMethod = () => {
        const clickMethod = { onClick: onTogglePopover };
        const hoverMethod = { onHover: onTogglePopover };
        return openOn === 'click' ? clickMethod : hoverMethod;
    };

    /**
     * Palauttaa joko funktion tai objektin.
     * Funktiota palautettaessa liitetään siihen Popoverin sulkeva metodi jota voi
     * käyttää sisemmissä childeissa (eli esim. tehdä nappi sisältöön joka sulkee itse Popoverin, kts. Storybook)
     * @param child
     * @returns {*}
     */
    const renderPopoverContent = (child) => {
        if (typeof child === 'function') {
            const InnerComponent = child;

            return (
                <InnerComponent
                    onClosePopover={onClosePopover}
                />
            );
        } else {
            return child;
        }
    };

    /**
     * Renderöi Popover-komponentin. React-popper pitää huolen siitä että
     * komponentti asemoituu oikein ja Transition hoitaa komponentin animoinnin.
     * @returns {*}
     */

    // Liitetään ensimmäiseen nodeen metodi joka triggeröi sisällön näyttämisen
    const nodes = React.Children.toArray(children);
    const showQuestionIcon = (nodes.length === 1 && typeof children[1] !== 'function');

    const triggerComponent = showQuestionIcon
        ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <button
                className={classNames('u-margin-left-tiny u-cursor-pointer is-focusable u-font-size-0', {
                    [triggerModifierClass]: triggerModifierClass !== '',
                })}
                aria-label={_trans('text.extra_info')}
            >
                <MDIcon icon="help_outline"/>
            </button>
        )
        : nodes[0];

    // Mahdolliset inline-tyylit (lähinnä leveys).
    const popoverContentStyle = {};
    if (width) {
        popoverContentStyle.width = width;
    }
    if (minWidth) {
        popoverContentStyle.minWidth = minWidth;
    }
    if (maxWidth) {
        popoverContentStyle.maxWidth = maxWidth;
    }

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: position,
        modifiers: [
            {
                name: 'arrow',
                options: { element: arrowElement }
            },
            {
                name: 'flip',
                options: { allowedAutoPlacements: ['left', 'right'] },
            },
            {
                name: 'preventOverflow',
                options: { enabled: true }
            },
            {
                name: 'offset',
                options: {
                    offset: [6, 6]
                }
            }
        ],
        strategy: 'fixed'
    });

    // Manager handlaa asemoinnin, Target on itse Popoverin näyttävä triggeri.
    // Popper-komponentti antaa alemmalle childille sen asemointiin liittyvät propsit ja
    // Transition-komponentti antaa alemmalle childille sen näkyvyyteen
    // liittyvän tilan (entered, entering, exiting, exited) joihin tartutaan CSS:llä.

    // <Manager className="c-popover-trigger u-display-inline-block">
    const popoverClass = classNames('c-popper c-popover', {
        [`c-popover--${popoverType}`]: popoverType !== ''
    });

    const useClickOutsideRef = useClickOutside(() => onClosePopover(), ['mouseup', 'touchend'], [referenceElement, popperElement]);
    const mergedRef = useMergedRef(useClickOutsideRef, focusTrapRef, setPopperElement);

    return (
        <Fragment>
            {/* eslint-disable-next-line react/display-name */}
            {React.cloneElement(triggerComponent, {
                ...getTriggerMethod(),
                'aria-haspopup': 'dialog',
                'aria-expanded': !!isStateOpen,
                ...(!describedById && isStateOpen) && { 'aria-describedby': uuid },
                'aria-controls': uuid,
                ref: setReferenceElement,
            })}
            {isStateOpen && ReactDOM.createPortal(
                <section
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    tabIndex={0}
                    ref={mergedRef}
                    style={{
                        ...styles.popper,
                        ...popoverContentStyle,
                        overflowY: 'auto',
                        ...(maxHeight && { maxHeight }),
                        zIndex: 10000,
                    }}
                    aria-modal="true"
                    role="dialog"
                    data-placement={position}
                    id={uuid}
                    key={uuid}
                    aria-hidden={!isStateOpen}
                    aria-labelledby={title !== '' ? `${uuid}Heading` : null}
                    aria-describedby={`${uuid}Content`}
                    {...attributes.popper}
                    className={popoverClass}
                    data-arrow={isArrowVisible}
                >
                    <PopoverContent
                        id={uuid}
                        openOn={openOn}
                        onClose={onClosePopover}
                        hasContentPadding={hasContentPadding}
                        title={title}
                    >
                        { !showQuestionIcon
                            ? renderPopoverContent(children[1])
                            : (
                                <div style={{ maxWidth: '320px' }}>
                                    {children}
                                </div>
                            )
                        }
                    </PopoverContent>
                    { isArrowVisible && <div ref={setArrowElement} style={styles.arrow} className="c-popover__arrow" />}
                </section>,
                document.querySelector('#popper-portal'))
            }
        </Fragment>
    );
};

Popover.defaultProps = {
    describedById: null,
    position: 'top',
    openOn: 'click',
    onOpen() {},
    onClose() {},
    onKeyDown() {},
    hasContentPadding: true,
    width: null,
    minWidth: null,
    maxWidth: null,
    maxHeight: null,
    popoverType: 'default',
    title: '',
    isArrowVisible: true,

    // Tämän on oltava NULL koska muuten Popover on aina joko auki tai kiinni eikä reagoi triggeriin.
    isOpen: null,

    onMount() {},
    onUnMount() {},
    modifiers: {},
    triggerModifierClass: '',
};

Popover.propTypes = {
    /**
     * Popoverin sisältö.
     * Ensimmäinen elementti on popoverin avaava trigger, jälkimmäinen itse sisältö.
     */
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]).isRequired,

    /**
     * Popoverin yksilöivä tunniste.
     * Käytetään aria-describedby -attribuutin kanssa.
     */
    describedById: PropTypes.string,

    /**
     * Popoverin sijoittuminen trigger-elementtiin nähden.
     */
    position: PropTypes.oneOf(positions),

    /**
     * Mikä trigger avaa popoverin.
     */
    openOn: PropTypes.oneOf(['click', 'hover']),

    /**
     * Mitä tehdään kun Popover avautuu
     */
    onOpen: PropTypes.func,

    /**
     * Mitä tehdään kun Popover sulkeutuu
     */
    onClose: PropTypes.func,

    /**
     * Mitä tehdään kun Popoverin ollessa auki painetaan näppäintä.
     */
    onKeyDown: PropTypes.func,

    /**
     * Onko sisällöllä paddingiä.
     */
    hasContentPadding: PropTypes.bool,

    /**
     * Sisällön leveys. Muuttumaton.
     */
    width: PropTypes.number,

    /**
     * Sisällön minimileveys.
     */
    minWidth: PropTypes.number,

    /**
     * Sisällön maksimileveys.
     */
    maxWidth: PropTypes.number,

    /**
     * Sisällön maksimikorkeus.
     */
    maxHeight: PropTypes.number,

    /**
     * Tarvittaessa popoverin tyyli voidaan määritellä erikseen.
     * CSS-luokka muodostuu tällä tyyliin .c-popover__content--[popoverType]
     */
    popoverType: PropTypes.oneOf(popOverTypes),

    /**
     * Popoverin otsikko.
     */
    title: PropTypes.string,

    /**
     * Näytetäänkö nuolta.
     */
    isArrowVisible: PropTypes.bool,

    /**
     * Onko Popover suljettu.
     * Voidaan käyttää kun halutaan sulkea Popover ulkopuolelta.
     */
    isOpen: PropTypes.bool,

    /**
     * Mitä tehdään kun komponentti on injektoitu DOMiin.
     */
    onMount: PropTypes.func,

    /**
     * Mitä tehdään kun komponentti on riivitään DOMista pois.
     */
    onUnMount: PropTypes.func,

    /**
     * Default trigger komponentille voidaan antaa modifier css luokka
     */
    triggerModifierClass: PropTypes.string,
};

export default Popover;
