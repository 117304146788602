import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sizes } from 'shared/constants';

function MDSpinner({ size, wrapped, modifierClass, title, }) {
    const iconClass = classNames('c-md-spinner u-font-size-0', {
        'c-md-spinner--tiny': size === sizes.TINY,
        'c-md-spinner--small': size === sizes.SMALL,
        'c-md-spinner--medium': size === sizes.MEDIUM,
        'c-md-spinner--large': size === sizes.LARGE,
        'c-md-spinner--huge': size === sizes.HUGE,

        [modifierClass]: modifierClass != '',
    });

    const strokeWidth = {
        [sizes.TINY]: 4,
        [sizes.SMALL]: 4,
        [sizes.MEDIUM]: 3,
        [sizes.LARGE]: 2,
        [sizes.HUGE]: 1,
    };

    let spinnerElement = (
        <span className={iconClass} title={title} role="status">
            <svg width="100%" height="100%" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                <g className="c-md-spinner__container" width="64" height="64" strokeWidth={strokeWidth[size]}>
                    <path
                        className="c-md-spinner__path"
                        fill="none"
                        d="M 14,1.5 A 12.5,12.5 0 1 1 1.5,14"
                        strokeLinecap="round"/>
                </g>
            </svg>
        </span>
    );

    if (size === sizes.MEDIUM && wrapped) {
        spinnerElement = (
            <div className="c-oima-spinner" aria-live="polite" aria-busy="true" title={title}>
                <svg width="100%" height="100%" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.9301 0H65.07C73.2731 0 81.1401 3.2587 86.9407 9.05921C92.7413 14.8597 96 22.7269 96 30.9301V65.07C96 73.2731 92.7413 81.1401 86.9407 86.9407C81.1401 92.7413 73.2731 96 65.07 96H30.9301C22.7269 96 14.8597 92.7413 9.05921 86.9407C3.2587 81.1401 0 73.2731 0 65.07V30.9301C0 22.7269 3.2587 14.8597 9.05921 9.05921C14.8597 3.2587 22.7269 0 30.9301 0Z"
                        fill="#F9725C"/>
                    <path
                        d="M47.9896 64.0707C43.3188 64.0707 39.0149 62.4028 35.8788 59.4002C34.3353 57.9283 33.1093 56.1566 32.2753 54.1939C31.4138 52.1274 30.97 49.9104 30.97 47.6715C30.97 45.4325 31.4138 43.2155 32.2753 41.149C33.1099 39.1866 34.3363 37.4153 35.8788 35.9427C39.0149 32.9404 43.3188 31.2722 47.9896 31.2722C52.6602 31.2722 56.9641 32.9404 60.1002 35.9427C61.643 37.4153 62.869 39.1866 63.7036 41.149C64.5654 43.2155 65.0089 45.4325 65.0089 47.6715C65.0089 49.9104 64.5654 52.1274 63.7036 54.1939C62.869 56.1563 61.643 57.9277 60.1002 59.4002C56.9641 62.4028 52.6602 64.0707 47.9896 64.0707Z"
                        fill="white" className="c-oima-spinner__eye"/>
                    <path
                        d="M47.9766 39.7976C46.4084 39.7976 44.9402 40.6647 43.8411 42.2344C42.6882 43.9844 42.0942 46.0431 42.1379 48.1384C42.0939 50.2331 42.6873 52.2918 43.8396 54.0421C44.9405 55.61 46.4087 56.4789 47.975 56.4789C49.5414 56.4789 51.0111 55.6115 52.1104 54.0421C53.2628 52.2915 53.8561 50.2321 53.8118 48.1369C53.8561 46.0416 53.2628 43.9822 52.1104 42.2319C51.0092 40.6637 49.5414 39.7948 47.975 39.7948"
                        fill="#F9725C" className="c-oima-spinner__pupil"/>
                </svg>
            </div>
        );
    }

    return (
        wrapped ?
            <div className="u-padding-small u-padding@medium u-text-center">
                {spinnerElement}
            </div> :
            spinnerElement
    );
}

MDSpinner.propTypes = {
    size: PropTypes.string,
    /* eslint-disable-next-line */
    wrapped: PropTypes.bool,
    modifierClass: PropTypes.string,
    title: PropTypes.string,
};

MDSpinner.defaultProps = {
    size: 'medium',
    wrapped: false,
    modifierClass: '',
    title: Translator.trans('spinner.wait'),
};

export default MDSpinner;
