import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _map from 'lodash/map';
import MDIcon from './MDIcon';
import alertTypes from 'shared/constants/alertTypes';

const feedbackIcon = {
    [alertTypes.SUCCESS]: 'check',
    [alertTypes.INFO]: 'info',
    [alertTypes.WARNING]: 'warning',
    [alertTypes.ERROR]: 'error',
};

// Vältytään eslintin ""react-hooks/exhaustive-deps" valitukselta. Tämä halutaan eksplisiittisesti
// tehdä näin, kerran mountissa ja toisen kerran kun onTimeout tapahtuu. Muuten eslint valittaa.
const useMountEffect = (fn, inputs) => useEffect(fn, inputs);

/**
 * Notification (aria roolilla. Lue lisää: https://www.w3.org/TR/wai-aria/roles#alert)
 * @param props
 * @returns {*}
 * @constructor
 */
const Notification = (props) => {
    const {
        type,
        onClick,
        timeout,
        onTimeout,
        linkUrl,
        children,
        isDismissable,
    } = props;

    useMountEffect(() => {
        let timer = null;
        // Mountatessa laskuri päälle
        if (timeout > 0) {
            timer = setTimeout(onTimeout, timeout);
        }

        // Unmountatessa nollataan laskuri
        return () => clearTimeout(timer);
    }, []);

    const notificationClass = classNames('c-notification', {
        'c-notification--success': type === alertTypes.SUCCESS,
        'c-notification--info': type === alertTypes.INFO,
        'c-notification--warning': type === alertTypes.WARNING,
        'c-notification--error': type === alertTypes.ERROR,
    });

    // Riippuen notifikaatin tyypistä määritä miten screen readerit viestin lukevat
    let ariaLiveType = 'polite';

    if (type === alertTypes.ERROR) {
        ariaLiveType = 'assertive';
    }

    let notificationTag = 'div';
    const notificationProps = {
        role: 'alert',
        className: notificationClass,
        onClick,
    };

    if (linkUrl !== '') {
        // Käytetään oikeaa linkki-tagia jos URL annettu.
        notificationProps.href = linkUrl;
        notificationTag = 'a';

        // Jos käytössä on linkki ei välitetä klikeistä.
        notificationProps.onClick = () => {};
    }

    const icon = feedbackIcon[type];
    return React.createElement(notificationTag, notificationProps, (
        <div className="o-stack o-stack--stretch o-stack--left">
            { icon !== '' && (
                <span className="c-notification__icon c-notification__icon-primary">
                    <MDIcon icon={icon} isFilled />
                </span>
            )}
            <div className="c-notification__body" aria-live={ariaLiveType}>
                {children}
            </div>
            {linkUrl !== '' && (
                <span className="c-notification__icon">
                    <MDIcon icon="keyboard_arrow_right" size="small"/>
                </span>
            )}
            { (isDismissable || timeout === 0) && (
                <button className="c-notification__icon o-interaction" onClick={onTimeout}>
                    <MDIcon icon="clear" size="small" />
                </button>
            )}
        </div>
    ));
};

Notification.propTypes = {
    /**
     * Notifikaation tyyppi (katso alertTypes.js)
     */
    type: PropTypes.oneOf(_map(alertTypes)),

    /**
     * Mitä tehdään kun notifikaatiota klikataan
     */
    onClick: PropTypes.func,

    /**
     * Missä ajassa (ms) notifikaatio häviää
     */
    timeout: PropTypes.number,

    /**
     * Mitä tehdään kun notifikaatio häviää
     */
    onTimeout: PropTypes.func,

    /**
     * Tekee koko notifikaatiosta linkin
     */
    linkUrl: PropTypes.string,

    /**
     * Notifikaation sisältö (kannattaisi ehkä olla ennemmin ihan propseina annettava...)
     */
    children: PropTypes.node.isRequired,

    /**
     * Voidaanko notifikaatio sulkea käyttäjän toimesta.
     */
    isDismissable: PropTypes.bool,
};

Notification.defaultProps = {
    icon: '',
    type: alertTypes.INFO,
    onClick() {},
    timeout: 0,
    onTimeout() {},
    linkUrl: '',
    isDismissable: true,
};

export default Notification;
