export const localesSupported = ['fi', 'sv_FI'];

export const translationSchema = {
    type: 'object',//['object', 'null'],
    // Muodostetaan lokalisaatioista oma property jokaisesta jotta BLockForm:ssa helpompi parsia.
    // Lopputulos on scheman kannalta sama.
    properties: localesSupported.reduce((acc, cur) => (
        Object.assign({}, acc, {
            [cur]: {
                type: 'string',
                minLength: 1,
            }
        })
    ), {}),
    /*propertyNames: { enum: localesSupported },
    patternProperties: {
        '': { type: 'string', minLength: 1 },
    },*/
    // Ei sallita muita käännöksiä kuin tuetut
    additionalProperties: false,
    // Vähintään Suomi vaaditaan
    required: [localesSupported[0]],
    nullable: true,
};
