const FI = 'fi';
const EN_US = 'en_US';
const SV_FI = 'sv_FI';

const localeMap = {
    [FI]: { locale: FI, label: 'FI', lang: 'fi', title: _trans('Suomeksi', {},'common') },
    [EN_US]: { locale: EN_US, label:'EN', lang: 'en',title: _trans('In English', {},'common') },
    [SV_FI]: { locale: SV_FI, label: 'SV', lang: 'sv',title: _trans('På Svenska', {},'common') },
};

export const languages = Object.values(localeMap);
export const languageOptions = Object.entries(localeMap).map(([locale, languageInfo]) => ({
    value: locale,
    label: languageInfo?.title,
}));

export const translateLocale = (locale) => localeMap[locale]?.title ?? '-';

export const getLocaleInfo = (locale) => localeMap[locale];

