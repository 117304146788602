import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import PropTypes from 'prop-types';
import { DataTable } from 'shared/containers/DataTable7';
import { Button, ConfirmButton } from 'shared/components';

// AccesstoDeletion nyt implementoitu vain TimeRegistrationiin niin muut toimii vielä kuten aiemminkin
// eslint-disable-next-line react/prop-types
export const DocumentsAttachmentList = ({ categoryType, relatedEntityId, isEditable, deleteButtonTitle, hasAccessToDeletion = true }) => {
    const dispatch = useDispatch();
    const documents = useSelector(select.documents.getDocuments);
    const isLoading = useSelector((state) => state.loading.effects.documents.fetchDocuments);
    const userId = useSelector(select.userMetadata.getUserId);

    useEffect(() => {
        dispatch.documents.fetchDocuments({ categoryType, relatedId: relatedEntityId });
    }, []);

    return (
        <DataTable
            isLoading={isLoading}
            emptyDataMessage={_trans('Ei dokumentteja', {}, 'documents')}
            data={documents}
            actionsColumn={<ActionColumn
                isEditable={isEditable}
                onFetch={() => dispatch.documents.fetchDocuments({ categoryType, relatedId: relatedEntityId })}
                onDelete={(uuid) => dispatch.documents.deleteDocument(uuid)}
                deleteButtonTitle={deleteButtonTitle}
                hasAccessToDeletion={hasAccessToDeletion}
                userId={userId}
            />}
            actionsColumnWidth={350}
            columns={[
                {
                    Header: _trans('Nimi', {}, 'common'),
                    accessor: 'name',
                },
                {
                    Header: _trans('Kuvaus', {}, 'common'),
                    accessor: 'description',
                    defaultValue: '-',
                    wordWrap: true,
                },
                {
                    Header: _trans('Luotu', {}, 'common'),
                    accessor: 'created',
                    type: 'date',
                    defaultValue: '-',
                },
                {
                    Header: _trans('Vanhenee', {}, 'common'),
                    accessor: (r) => r.expireDate === undefined && r.category.isExpiryAfterContractTerminating
                        ? _trans('Sopimuksen päättymisen jälkeen %year% vuotta ja %month% kuukautta', {
                            year: r.category.defaultExpiryYear,
                            month: r.category.defaultExpiryMonth,
                        }, 'documents')
                        : _toLocaleDate(r.expireDate),
                    // type: 'date',
                    // defaultValue: '-',
                },
                {
                    Header: _trans('Kategoria', {}, 'documents'),
                    accessor: 'category.name',
                },
            ]}
        />
    );
};

// eslint-disable-next-line react/prop-types
const ActionColumn = ({ original, onDelete, onFetch, isEditable, deleteButtonTitle, hasAccessToDeletion, userId }) => (
    <div>
        <div className="o-stack o-stack--justify">
            <div className="u-margin-right-small">
                <Button
                    href={`/api/v3/documents/${original.uuid}/download`}
                    target="_blank"
                    mdIcon="cloud_download"
                >
                    {_trans('Avaa dokumentti', {}, 'documents')}
                </Button>
            </div>
            <div>
                <ConfirmButton
                    disabled={!isEditable || !(hasAccessToDeletion || original.uploaderId === userId)}
                    title={!isEditable && deleteButtonTitle ? deleteButtonTitle : ''}
                    mdIcon="delete_forever"
                    confirmText={_trans('Haluatko varmasti poistaa %documentName% dokumentin?', { documentName: original.name }, 'documents')}
                    negative
                    text={_trans('Poista', {}, 'common')}
                    onClick={() => onDelete(original.uuid).then(() => onFetch())}
                />
            </div>
        </div>
        {original.isPayPeriodDataGenerated && isEditable && (
            <div className="u-margin-top-tiny u-muted">
                {_trans('Voit poistaa dokumentin myöskin poistamalla lisäkorvauksen.', {}, 'documents')}
            </div>
        )}
    </div>
);


ActionColumn.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onFetch: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    original: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        uploaderId: PropTypes.number.isRequired,
        isPayPeriodDataGenerated: PropTypes.bool.isRequired,
    }).isRequired,
};
DocumentsAttachmentList.propTypes = {
    categoryType: PropTypes.string.isRequired,
    relatedEntityId: PropTypes.number.isRequired,
    isEditable: PropTypes.bool,
};
DocumentsAttachmentList.defaultProps = {
    isEditable: true
};

