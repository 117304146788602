import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { select } from '@rematch/select';
import UserBlock from 'shared/components/UserBlock';
import ActionBar from 'shared/components/ActionBar';
import Button from 'shared/components/Button';
import { generateRoutes } from 'ContractV3/constants/routes';
import { isCommune } from 'shared/utils/commonUtils';
import companyUserRelationTypes from 'shared/constants/companyUserRelationTypes';
import { getPartyQueryByRelation } from 'ContractV3/utils/getPartyQueryByRelation';
import resolveUrl from 'shared/utils/resolveUrl';
import { fieldNames } from 'ContractV3/constants/fieldNames';

const routes = generateRoutes(isCommune());

const relationTypes = {
    1: 'customer',
    2: 'employer',
    3: 'employee',
};
/**
 * Valittu osapuoli.
 * Tarjoaa myös napit tietojen muokkaukseen tai valitun osapuolen vaihtoon
 * (ainoastaan uutta sopimusta luotaessa).
 * @param selectedParty
 * @param relationType
 * @param name
 * @param secondaryInfo
 * @param onRemoveParty
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectedParty = ({ selectedParty, relationType, name, secondaryInfo, onRemoveParty }) => {
    const dispatch = useDispatch();
    const benefitDecisionId = useParams()?.benefitDecisionId;
    const isNewContract = useSelector(select.contract.isNewContract);
    const isCloningContract = useSelector(select.contract.isCloningContract);
    // Kloonatessa soppari-id:t saadaan urlista
    const { contractId, contractDataId } = useParams();
    const isDraftContract = useSelector(select.contract.isDraftContract);
    const templateId = useSelector(select.contract.getTemplateId);
    const isCommune = useSelector(select.userMetadata.isCommune);
    const isCompany = useSelector(select.userMetadata.isCompany);
    const isOperator = isCompany || isCommune;
    const isEmployer = useSelector(select.userMetadata.isEmployer);
    const route = `${resolveUrl(
        isCloningContract ? routes.CLONE_CONTRACT : routes.NEW_CONTRACT, 
        { benefitDecisionId, contractId, contractDataId }
    )}?template=${templateId}${getPartyQueryByRelation(relationType, selectedParty?.userId ?? '')}`;

    // Henkilön voi vaihtaa vain uusilla- ja luonnossoparreilla. Lisäksi ainakin aluksi nyt vain metadatahenkilöt.
    const isPartyEmployeeOrCared = [fieldNames.EMPLOYEE, fieldNames.CARED].includes(name) || name.startsWith(`${fieldNames.EMPLOYEE}.`);
    const canChangeParty = isPartyEmployeeOrCared
        ? isNewContract
        : (isNewContract || isDraftContract || ! isPartyEmployeeOrCared);

    return (
        <div aria-labelledby={name}>
            <UserBlock
                user={selectedParty}
                size="small"
                secondaryInfo={secondaryInfo}
                hasLinkToDetails={isOperator}
                relationType={relationTypes[relationType]}
            />
            {canChangeParty && (
                <ActionBar modifierClass="u-margin-top">
                    <Button
                        ariaLabel={
                            name.startsWith(`${fieldNames.EMPLOYEE}.`)
                                ? _trans('Vaihda %role%', { role: _trans('contract.employee.singular') }, 'contract')
                                : _trans('Vaihda hoidettava', {}, 'contract')
                        }
                        outline
                        mdIcon="swap_horiz"
                        onClick={onRemoveParty}
                        modifierClass="u-1/1"
                    >
                        {_trans('Vaihda', {}, 'common')}
                    </Button>
                    {/* Muokata voi tällä hetkellä vain työntekijää ja hoidettavaa. Työnantaja ei voi muokata tietoja */}
                    {isPartyEmployeeOrCared && !isEmployer && (
                        <Button
                            ariaLabel={
                                name.startsWith(`${fieldNames.EMPLOYEE}.`)
                                    ? _trans('Muokkaa %role%', { role: _trans('contract.employee.singular') }, 'contract')
                                    : _trans('Muokkaa hoidettavaa', {}, 'contract')
                            }
                            outline
                            mdIcon="edit"
                            modifierClass="u-1/1"
                            onClick={() => dispatch(push(route))}
                        >
                            {_trans('Muokkaa', {}, 'common')}
                        </Button>
                    )}
                </ActionBar>
            )}
        </div>
    );
};

SelectedParty.propTypes = {
    selectedParty: PropTypes.object.isRequired,
    relationType: PropTypes.oneOf(Object.values(companyUserRelationTypes)).isRequired,
    name: PropTypes.string.isRequired,
    secondaryInfo: PropTypes.oneOf(['socialSecurityNumber', 'email']),
    onRemoveParty: PropTypes.func.isRequired,
};

SelectedParty.defaultProps = {
    secondaryInfo: 'socialSecurityNumber',
};

